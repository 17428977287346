import { toast } from 'react-toastify';

export const popConfig = {
  icon: false,
  position: 'bottom-right',
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const errorConfig = {
  icon: true,
  position: 'bottom-right',
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function pop(message) {
  toast.info(message, popConfig);
}

export default { pop };
