import React from 'react';
import { useMachine } from '@xstate/react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';

import Machine from './MachineSettings';
import { debug } from '../../../envConfig';
import { constructAddress } from '../../../shared/functions';
import { FormFields } from '../../../shared/styles';
import upgradeRushSchema from './validationSchemas';

export default function UpgradeRush() {
  const { id } = useParams();
  const [formState, send] = useMachine(Machine(id), {
    devTools: debug.xstate,
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(upgradeRushSchema),
  });

  const submitForm = e => {
    send('SUBMIT', e);
  };

  if (formState.matches('init')) {
    return <p>Loading...</p>;
  }
  if (formState.matches('loadingError')) {
    return <p>There was an internal error please reload and try again.</p>;
  }
  if (formState.matches('waiting')) {
    return (
      <StyledUpgradeRush className="styledUpgradeRush">
        <FormFields onSubmit={handleSubmit(submitForm)}>
          {formState.context.mailAddress && (
            <>
              <p className="input-group--title input-group--bold">
                Address Mailing to:
              </p>
              {constructAddress(formState.context.mailAddress)}
              <hr />
            </>
          )}
          <div className="radio-group">
            <p className="input-group--title input-group--bold">
              What Rush method do you prefer? <span className="error">*</span>
            </p>
            <div
              className="__options"
              role="group"
              aria-labelledby="mailing services"
            >
              {formState.context.services.map(
                ({ id: serviceID, name, displayName, price }) => {
                  if (!displayName.includes('International')) {
                    return (
                      <div className="option" key={serviceID}>
                        <input
                          id={name}
                          name="shippingMethod"
                          type="radio"
                          value={serviceID}
                          {...register('shippingMethod')}
                        />
                        <label htmlFor={name}>
                          {' '}
                          {displayName}{' '}
                          <strong>
                            (<span>$</span>
                            {price}.00)
                          </strong>
                        </label>
                      </div>
                    );
                  }
                  return null;
                },
              )}
            </div>
            <ErrorMessage
              errors={errors}
              name="shippingMethod"
              as="span"
              className="error"
            />
          </div>
          <button type="submit" className="btn btn--blue">
            Submit
          </button>
        </FormFields>
      </StyledUpgradeRush>
    );
  }

  return <div>Redirecting to payment form:</div>;
}

const StyledUpgradeRush = styled.div`
  max-width: 500px;
  hr {
    margin: 50px 0;
  }
  button {
    margin-top: 30px;
  }
  .option {
    padding: 5px 0;
  }
  input,
  label {
    cursor: pointer;
  }
`;
