import React from 'react';
import { useApolloClient, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { paymentGateway } from '../envConfig';
import Button from '../components/UI/Button';
import DashboardTemplate from './Templates/DashboardTemplate';

export function redirectToPaymentGateway(token) {
  const form = document.createElement('form');

  form.method = 'POST';
  form.action = paymentGateway.paymentUrl;

  const element1 = document.createElement('input');
  element1.type = 'hidden';
  element1.name = 'token';
  element1.value = `${token}`;
  form.appendChild(element1);

  document.body.appendChild(form);

  form.submit();
}

const CREATE_PURCHASE_TOKEN = gql`
  mutation CREATE_PURCHASE_TOKEN($applicationId: String!) {
    applications {
      application(id: $applicationId) {
        renewApplication
      }
    }
  }
`;

const payNow = async (id, client) => {
  // return redirectToPaymentGateway(token);
  return client
    .mutate({
      mutation: CREATE_PURCHASE_TOKEN,
      variables: { applicationId: id },
    })
    .then(({ data }, error) => {
      if (error) {
        throw new Error();
      }
      const { renewApplication: token } = data.applications.application;
      return redirectToPaymentGateway(token);
    });
};

const RenewEvaluation = () => {
  const { id } = useParams();
  const client = useApolloClient();
  return (
    <DashboardTemplate>
      <Content>
        <h1>
          <strong>Review After 45 Days</strong>
        </h1>
        <h2>Price: $50.00</h2>
        <p>
          Requests for review of the evaluation, using documents received with
          the original application, must be made in writing within 45 days of
          receiving the report. Requests for review must be submitted in writing
          either via postal service or email. Please include your reference
          number and be very specific in your concerns.
        </p>
        <Button title="Pay" text="Pay" onClick={() => payNow(id, client)} />
      </Content>
    </DashboardTemplate>
  );
};

export default RenewEvaluation;

const Content = styled.div`
  p {
    margin-top: 2rem;
    font-size: 18px;
    max-width: 50rem;
    line-height: 1.5;
  }
  button {
    margin-top: 2rem;
  }
`;
