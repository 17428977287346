import { Machine, assign } from 'xstate';
import { useApolloClient } from '@apollo/client';
// import { returnService } from '../../../shared/functions';
// IMPORT MACHINE CONFIG
import machineConfig from './MachineConfig';
import {
  returnServiceByType,
  servicesByCountry,
} from '../../../shared/functions';

// IMPORT QUERIES AND MUTATIONS
import queries from './queries';
import mutations from './mutations';

function createServices() {
  // GET APOLLO CLIENT TO SEND TO MUTATIONS
  const client = useApolloClient();

  // GET QUERIES AND MUTATIONS FROM IMPORT
  const { getServices } = queries(client);
  const { payUpgradeRush } = mutations(client);

  return {
    guards: {},

    services: {
      getAppAndServices: ctx => {
        return getServices(ctx.applicationId);
      },
      createTokenAndRedirect: (ctx, e) => {
        return payUpgradeRush({
          applicationID: ctx.applicationId,
          serviceId: e.shippingMethod,
        });
      },
    },

    actions: {
      addAddressAndServices: assign({
        services: (_, e) => {
          const mailAddressLocation = e.data?.address?.country || '';
          let services = returnServiceByType(e.data.services, 'RUSH').filter(
            service => {
              // gets rid of option for no rush service
              return !service.displayName.toLowerCase().includes('no rush');
            },
          );
          // filter services based on mail location
          if (mailAddressLocation) {
            services = servicesByCountry(services, mailAddressLocation);
          }
          return services;
        },
        mailAddress: (_, e) => {
          return e.data.address;
        },
      }),
    },
  };
}

function create(id) {
  // CREATE ALL SERVICES FROM ABOVE
  const services = createServices();

  // RETURN MACHINE WITH CONFIG AND SERVICES
  return Machine({ ...machineConfig(id) }, { ...services });
}

export default create;
