import { Machine, assign } from 'xstate';
import FormMachineConfig from '../../Machines/FormMachineConfig';
import {
  FORM_MACHINE_GET_APPLICATION,
  GET_ALL_SERVICES,
} from '../../queries_mutations/queries';
import {
  CREATE_APPLICATION,
  UPDATE_TERMS,
} from '../../queries_mutations/mutations';
import paymentMutations from '../../queries_mutations/paymentGateway/mutations';
import paymentQueries from '../../queries_mutations/paymentGateway/queries';

import step1Actions from './FormMachineFunctions/Step1Functions/actions';
import step2Actions from './FormMachineFunctions/Step2Functions/actions';
import step3Actions from './FormMachineFunctions/Step3Functions/actions';
import step4Actions from './FormMachineFunctions/Step4Functions/actions';
import step5Actions from './FormMachineFunctions/Step5Functions/actions';

function createServices(client) {
  // creating the actions and services in other files for legability
  const {
    actions: sOneActions,
    services: sOneService,
    guards: sOneGuards,
  } = step1Actions(client);
  const {
    actions: sTwoActions,
    services: sTWoService,
    guards: sTwoGuards,
  } = step2Actions(client);
  const {
    actions: sThreeActions,
    services: sThreeService,
    guards: sThreeGuards,
  } = step3Actions(client);
  const {
    actions: sFourActions,
    services: sFourService,
    guards: sFourGuards,
  } = step4Actions(client);
  const {
    actions: sFiveActions,
    services: sFiveService,
    guards: sFiveGuards,
  } = step5Actions(client);

  const { payNow } = paymentMutations(client);
  const { getCart } = paymentQueries(client);

  return {
    guards: {
      isNew: ctx => ctx.isNewApplicaiton,
      ...sOneGuards,
      ...sTwoGuards,
      ...sThreeGuards,
      ...sFourGuards,
      ...sFiveGuards,
    },

    services: {
      ...sOneService,
      ...sTWoService,
      ...sThreeService,
      ...sFourService,
      ...sFiveService,
      getServicesAndAdmins: () => {
        return client
          .mutate({
            mutation: GET_ALL_SERVICES,
          })
          .then((data, error) => {
            // console.log('getServicesAndAdmins: ', data);
            if (error) throw new Error();
            return {
              services: data.data.services.all,
              workflow: data.data.workflow.all,
            };
          });
      },
      createApplication: ctx => {
        // THIS SETS THE ADMIN THAT HANDLES THE NEW APPLICATIONS
        const newAppHandler = ctx.workflow.filter(
          obj => obj.name === 'newAppHandler',
        )[0] || { adminId: '' };

        return client
          .mutate({
            mutation: CREATE_APPLICATION,
            variables: {
              appData: {
                evaluatorId: newAppHandler.adminId,
                status: 'Application Submitted',
              },
            },
          })
          .then(({ data }, error) => {
            if (error) throw new Error();
            // console.log('application created: ', data.applications.me.create);
            return {
              id: data.applications.me.create.id,
              created: data.applications.me.create.createdAt,
              documentsID: data.applications.me.create.appDocumentsInfo.id,
              deliveryInstructionsID:
                data.applications.me.create.deliveryInstructions.id,
            };
          });
      },

      getApplication: ctx =>
        client
          .query({
            query: FORM_MACHINE_GET_APPLICATION,
            variables: { appId: ctx.applicationID },
          })
          .then((data, error) => {
            if (error) throw new Error(error);
            return data.data.applications.me.application;
          }),

      // UPDATING TERMS ON REVIEW APPLICATION STEP
      updateTerms: (ctx, e) => {
        // console.log('fn:service:updateTerms', e);
        return client
          .mutate({
            mutation: UPDATE_TERMS,
            variables: {
              appId: ctx.applicationID,
              initials: e.initials,
            },
          })
          .then((data, error) => {
            if (error) {
              // do we throw?
            }
            return data.data.applications.application.updateTerms;
          });
      },

      postApplication: () =>
        new Promise(resolve => {
          setTimeout(() => resolve(), 3000);
        }),

      validateApplication: () =>
        new Promise(resolve => {
          setTimeout(() => resolve(), 3000);
        }),

      payApplication: ctx => {
        // console.log('paying application');
        payNow(ctx.applicationID);
      },
      createCart: ctx => {
        return getCart({
          variables: {
            applicationId: ctx.applicationID,
          },
        });
      },
    },
    actions: {
      setDeliveryInstructionsID: assign({
        step5: (ctx, { data }) => {
          return { ...ctx.step5, id: data.deliveryInstructionsID };
        },
      }),
      setDocumentsId: assign({
        documentsDBRefId: (_, { data }) => data.documentsID,
      }),
      updateApplicationId: assign({ applicationID: (_, e) => e.data.id }),
      setCreatedTimeInCTX: assign({ created: (_, e) => e.data.created }),
      // LOADING ACTIONS
      setIsNewApplicationFalse: assign({
        isNewApplicaiton: false,
      }),
      setApplicationAddress: assign({
        applicationAddressID: (_, e) => e.data.applicantInfo?.address?.id || '',
        addressOnFile: (_, e) => {
          const address = {
            ...e.data.applicantInfo?.address,
            __typename: undefined,
          };
          return address || '';
        },
      }),

      ...sOneActions,
      ...sTwoActions,
      ...sThreeActions,
      ...sFourActions,
      ...sFiveActions,

      setServicesToContext: assign({
        services: (_, { data }) => data.services,
        workflow: (_, { data }) => data.workflow,
      }),

      // TERMS OF SERVICE AGREEMENT ACTIONS:
      updateTermsInfoCtx: assign({
        termsAgreementInitials: (_, e) => e.data.termsAgreement,
        termsSignedDate: (_, e) => e.data.termsSignedDate,
      }),

      saveCartToContext: assign({
        cart: (_, { data }) => {
          return data;
        },
      }),
      saveDraftToDatabase: () =>
        // eslint-disable-next-line
        console.log('saving draft to database'),

      saveToLocalStorage: () =>
        // eslint-disable-next-line
        console.log('saving data to local storage'),

      setCurrentStep: () =>
        // eslint-disable-next-line
        console.log('setting current step'),

      logError: (_, e) =>
        // eslint-disable-next-line
        console.log('Error Message:', e.data.message),
    },
  };
}

function create(isNewApplication, applicationId, skipToPayStep, client) {
  const services = createServices(client);
  return Machine(
    { ...FormMachineConfig(isNewApplication, applicationId, skipToPayStep) },
    { ...services },
  );
}

export default create;
