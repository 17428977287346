import { gql } from '@apollo/client';
import { redirectToPaymentGateway } from '../../../queries_mutations/paymentGateway/mutations';

export const CREATE_PURCHASE_TOKEN = gql`
  mutation Mutation($applicationID: String!, $serviceId: String!) {
    applications {
      me {
        application(id: $applicationID) {
          createUpgradeRushPurchaseToken(serviceId: $serviceId)
        }
      }
    }
  }
`;

export default client => {
  const payUpgradeRush = ({ applicationID, serviceId }) => {
    return client
      .mutate({
        mutation: CREATE_PURCHASE_TOKEN,
        variables: {
          applicationID,
          serviceId,
        },
      })
      .then(({ data }, error) => {
        if (error) {
          throw new Error(error);
        }
        const { createUpgradeRushPurchaseToken: token } =
          data.applications.me.application;
        return redirectToPaymentGateway(token);
      });
  };

  return {
    payUpgradeRush,
  };
};
