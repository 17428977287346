import React from 'react';
import styled from 'styled-components';

import LoadingSpinner from '../UI/Loading';
import mapImage from '../../assets/Map.svg';

const Loading = () => {
  return (
    <LoginPageContainer className="LoginPageContainer">
      <p className="__logo-cont">
        <a href="https://www.gceus.com" className="__logo">
          GLOBAL CREDENTIAL EVALUATORS
        </a>
      </p>
      <div className="content">
        <div className="__block">
          <h1>Loading...</h1>
          <p className="__link-cont">
            <div className="loading">
              <LoadingSpinner />
            </div>
          </p>
        </div>
      </div>
      <Map />
    </LoginPageContainer>
  );
};

export default Loading;

const Map = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding-bottom: 60%;
  background-image: url(${mapImage});
  background-size: contain;
  background-position: center;
  z-index: 0;
  @media only screen and (max-width: 900px) {
    top: 0;
    right: 0;
    left: unset;
  }
`;

const LoginPageContainer = styled.div`
  min-height: 100vh;
  overflow: hidden;
  background-color: #14527f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px 0;
  position: relative;

  .__block {
    background-color: white;
    padding: 80px 50px;
    max-width: 550px;
    border-radius: 10px;
    h1 {
      color: #14527f;
      font-weight: 800;
      fontsize: 35px;
      margin-bottom: 30px;
    }
  }
  .__logo-cont {
    position: absolute;
    top: 30px;
    left: 5%;
    text-align: left;
    align-self: flex-start;
    margin: 0 auto;
    margin-bottom: 10px !important;
  }
  .__logo {
    color: white;
    display: inline-block;
    font-size: 25px;
    font-weight: 700;
  }
  .content {
    margin: 0 auto;
    margin-top: 30px;
    position: relative;
    z-index: 1;
    h2 {
      line-height: 1.7;
      margin-bottom: 15px;
      @media only screen and (max-width: 900px) {
        br {
          display: none;
        }
      }
      @media only screen and (max-width: 1250px) {
        font-size: 1.2em;
      }
    }
    p {
      margin-bottom: 30px;
      line-height: 1.7;
      color: #2e2e2e;
      font-size: 18px;
    }
    .__link-cont {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .loading {
      width: 75px;
      height: 75px;
    }
    .__link {
      border: solid #14527f 2px;
      min-width: 165px;
      font-size: 16px;
      padding: 10px;
      border-radius: 3px;
      display: inline-block;
      text-align: center;
      transition: all ease 0.3s;
      font-weight: 700;
      &:hover,
      &:focus {
        background-color: #14527f;
        color: white;
      }
    }
    .__block {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;
