import step5State from '../components/form-components/FormMachineFunctions/Step5Functions/Step5StateConfig';

function FormMachineConfig(
  applicationId,
  isNewApplication = true,
  skipToPayStep = false,
) {
  return {
    id: 'ApplicationWizardMachine',
    initial: 'loading',
    context: {
      skipToPayStep,
      isNewApplicaiton: isNewApplication,
      applicationID: applicationId || undefined,
      applicationAddressID: '',
      services: [],
      step1: {
        id: '',
        data: '',
        complete: false,
      },
      step2: {
        data: [],
        complete: false,
      },
      step3: {
        id: '',
        data: {
          documents: [],
        },
        complete: false,
      },
      step4: {
        serviceId: '',
        complete: false,
      },
      step5: {
        id: '',
        complete: false,
        created: false,
      },
      applicationData: undefined,
    },
    states: {
      loading: {
        invoke: {
          src: 'getServicesAndAdmins',
          onDone: {
            target: 'checkApplicationState',
            actions: 'setServicesToContext',
          },
          onError: { target: 'error' },
        },
      },
      checkApplicationState: {
        always: [
          {
            cond: 'isNew',
            target: 'newApplication',
          },
          {
            target: 'loadingApplication',
          },
        ],
      },
      newApplication: {
        invoke: {
          src: 'createApplication',
          onDone: {
            actions: [
              'setIsNewApplicationFalse',
              'updateApplicationId',
              'setCreatedTimeInCTX',
              'setDocumentsId',
              'setDeliveryInstructionsID',
            ],
            target: 'editing',
          },
          onError: { target: 'error' },
        },
      },

      loadingApplication: {
        invoke: {
          src: 'getApplication',
          onDone: [
            {
              cond: context => context.skipToPayStep,
              actions: [
                'setApplicationAddress',
                'updateTermsInfoCtx',
                'loadStep1DataIntoContext',
                'loadStep2DataIntoContext',
                'loadStep3DataIntoContext',
                'loadStep4DataIntoContext',
                'loadStep5DataIntoContext',
              ],
              target: 'editing.reviewPayment',
            },
            {
              actions: [
                'setApplicationAddress',
                'updateTermsInfoCtx',
                'loadStep1DataIntoContext',
                'loadStep2DataIntoContext',
                'loadStep3DataIntoContext',
                'loadStep4DataIntoContext',
                'loadStep5DataIntoContext',
              ],
              target: 'editing',
            },
          ],
          onError: { target: 'error' },
        },
      },

      editing: {
        initial: 'stepOne',
        on: {
          STEP_ONE: { target: '#editingStepOne' },
          STEP_TWO: { target: '#editingStepTwo' },
          STEP_THREE: { target: '#editingStepThree' },
          STEP_FOUR: { target: '#editingStepFour' },
          STEP_FIVE: { target: '#editingStepFive' },
        },
        states: {
          stepOne: {
            id: 'editingStepOne',
            initial: 'pending',
            on: {
              STEP_COMPLETE: [
                {
                  cond: 'isStep1Complete',
                  actions: ['setStep1Data', 'saveToLocalStorage'],
                  target: '.updateApplicantInfo',
                },
                {
                  actions: ['setStep1Data', 'saveToLocalStorage'],
                  target: '.createApplicantInfo',
                },
              ],

              STEP_ERROR: { target: '#error' },
            },
            states: {
              pending: {},
              createApplicantInfo: {
                invoke: {
                  src: 'createApplicantInfo',
                  onDone: {
                    actions: [
                      'updateApplicantId',
                      'setStep1Complete',
                      'updateAddressID',
                      'updateAddressOnFileToContext',
                    ],
                    target: '#editingStepTwo',
                  },

                  onError: { target: '#error' },
                },
              },
              updateApplicantInfo: {
                invoke: {
                  src: 'updateApplicantInfo',
                  onDone: {
                    actions: ['updateAddressOnFileToContext'],
                    target: '#editingStepTwo',
                  },

                  onError: { target: '#error' },
                },
              },
            },
          },
          stepTwo: {
            id: 'editingStepTwo',
            initial: 'pending',
            on: {
              STEP_COMPLETE: {
                target: '#editingStepThree',
              },
              EDIT_SCHOOL: {
                target: '.editSchool',
              },
              DELETE_SCHOOL: {
                target: '.deleteSchool',
              },
              ADD_SCHOOL: {
                target: '.addNewSchool',
              },

              PREV_STEP: {
                target: '#editingStepOne',
                actions: ['saveToLocalStorage'],
              },
            },
            states: {
              pending: {},
              addNewSchool: {
                invoke: {
                  src: 'addNewSchool',
                  onDone: {
                    actions: ['addSchoolToContext', 'setStep2Complete'],
                    target: 'pending',
                  },

                  onError: { target: '#error' },
                },
              },
              editSchool: {
                invoke: {
                  src: 'editSchool',
                  onDone: {
                    actions: ['updateSchoolToContext', 'setStep2Complete'],
                    target: 'pending',
                  },
                  onError: { target: '#error' },
                },
              },
              deleteSchool: {
                invoke: {
                  src: 'deleteSchool',
                  onDone: {
                    actions: ['removeSchoolfromContext', 'setStep2Complete'],
                    target: 'pending',
                  },
                  onError: {
                    target: '#error',
                  },
                },
              },
            },
          },
          stepThree: {
            id: 'editingStepThree',
            initial: 'waiting',
            on: {
              STEP_COMPLETE: {
                target: '.saveDocumentData',
              },
              PREV_STEP: {
                target: '#editingStepTwo',
              },
            },
            states: {
              waiting: {
                on: {
                  uploadDocument: 'uploadingDocument',
                  DELETE_DOCUMENT: 'deleteDocument',
                },
              },
              saveDocumentData: {
                invoke: {
                  src: 'saveDocumentData',
                  onDone: {
                    actions: ['setStep3Data', 'setStep3Complete'],
                    target: '#editingStepFour',
                  },

                  onError: { target: '#error' },
                },
              },
              uploadingDocument: {
                invoke: {
                  src: 'uploadingDocument',
                  onDone: {
                    actions: [],
                    target: 'refetchDocuments',
                  },

                  onError: { target: 'errorUploadingDocuments' },
                },
              },
              refetchDocuments: {
                invoke: {
                  src: 'fetchDocuments',
                  onDone: {
                    actions: ['saveDocumentsToContext'],
                    target: 'waiting',
                  },
                  onError: { target: 'errorUploadingDocuments' },
                },
              },
              deleteDocument: {
                invoke: {
                  src: 'deleteDocument',
                  onDone: {
                    target: 'refetchDocuments',
                  },
                  onError: { target: 'errorUploadingDocuments' },
                },
              },
              errorUploadingDocuments: {
                after: {
                  10000: { target: 'waiting' },
                },
              },
            },
          },
          stepFour: {
            id: 'editingStepFour',
            initial: 'pending',
            on: {
              UPDATE_SERVICE: {
                target: '.updatingService',
              },
              PREV_STEP: {
                target: '#editingStepThree',
              },
            },
            states: {
              pending: {},
              updatingService: {
                invoke: {
                  src: 'updateServiceStep4',
                  onDone: {
                    actions: 'setStep4Data',
                    target: '#editingStepFive',
                  },
                  onError: {
                    target: '#error',
                  },
                },
              },
            },
          },
          stepFive: {
            ...step5State,
          },
          reviewApplication: {
            initial: 'idle',
            id: 'editingReviewApplication',
            on: {
              STEP_COMPLETE: {
                target: '#editingReviewPayment',
                actions: ['saveToLocalStorage', 'saveDraftToDatabase'],
              },
              PREV_STEP: {
                target: '#editingStepFive',
                actions: ['saveToLocalStorage', 'saveDraftToDatabase'],
              },
              ACCEPT_TERMS: {
                target: '.updateTermsSignature',
              },
            },
            states: {
              idle: {},
              updateTermsSignature: {
                invoke: {
                  src: 'updateTerms',
                  onDone: {
                    actions: ['updateTermsInfoCtx'],
                    target: 'idle',
                  },
                  onError: {
                    target: '#error',
                  },
                },
              },
            },
          },
          // todo: need to rework this a bit.
          reviewPayment: {
            id: 'editingReviewPayment',
            initial: 'creatingCart',
            states: {
              creatingCart: {
                invoke: {
                  src: 'createCart',
                  onDone: {
                    actions: ['saveCartToContext'],
                    target: 'waiting',
                  },
                  onError: {
                    target: '#error',
                  },
                },
              },
              waiting: {
                on: {
                  STEP_COMPLETE: {
                    target: '#paying',
                    actions: [],
                  },
                  PREV_STEP: {
                    target: '#editingReviewApplication',
                    actions: [],
                  },
                },
              },
            },
          },
        },
      },

      // todo: maybe some substates here?
      paying: {
        id: 'paying',
        invoke: {
          src: 'payApplication',
          onDone: {
            target: 'success',
          },
          onError: {
            target: 'error',
          },
        },
      },
      success: {
        type: 'final',
      },
      error: {
        id: 'error',
        entry: ['logError'],
      },
    },
  };
}

export default FormMachineConfig;
