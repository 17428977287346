import React from 'react';
import styled from 'styled-components';

import DashboardTemplate from '../Templates/DashboardTemplate';

const Content = styled.div`
  p {
    margin-top: 2rem;
    font-size: 18px;
    max-width: 50rem;
    line-height: 1.5;
  }
  button {
    margin-top: 2rem;
  }
`;

const ApplicationError = () => {
  return (
    <DashboardTemplate>
      <Content>
        <h2>Opps, Application Error</h2>
        <p>ADD COPY</p>
      </Content>
    </DashboardTemplate>
  );
};

export default ApplicationError;
