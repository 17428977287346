import React from 'react';
import { createRoot } from 'react-dom/client';

import { inspect } from '@xstate/inspect';
import * as Sentry from '@sentry/react';
import { inject } from '@vercel/analytics';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { debug, sentryConfig } from './envConfig';
import App from './App';
import ApolloAuthProvider from './components/auth/ApolloAuthProvider';
import { Auth0ProviderWithNavigate } from './components/auth/Auth0ProviderWithNavigate';

if (debug.xstate) {
  inspect({
    url: 'https://statecharts.io/inspect', // (default)
    iframe: false, // open in new window
  });
}

// Vercel Analytics
inject();

if (
  !window.location.host.includes('127.0.0.1') &&
  !window.location.host.includes('localhost')
) {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    disable_session_recording: true,

    // eslint-disable-next-line object-shorthand
    loaded(ph) {
      if (ph.isFeatureEnabled('disable-autocapture')) {
        // eslint-disable-next-line no-param-reassign
        ph.config.autocapture = false;
      }

      if (ph.isFeatureEnabled('enable-session-recording')) {
        // eslint-disable-next-line no-param-reassign
        ph.config.disable_session_recording = false;
      }
    },
  });
}

Sentry.init({
  ...sentryConfig,
  integrations: [new posthog.SentryIntegration(posthog, 'GCE', 'gce-3e')],
  attachStacktrace: true,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred.</p>}>
    <PostHogProvider client={posthog}>
      <Auth0ProviderWithNavigate>
        <ApolloAuthProvider>
          <ToastContainer
            style={{
              lineHeight: '1.5',
              width: '50vw',
              maxWidth: '600px',
            }}
          />
          <App />
        </ApolloAuthProvider>
      </Auth0ProviderWithNavigate>
    </PostHogProvider>
  </Sentry.ErrorBoundary>,
);
