import React from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

import { v4 as uuid } from 'uuid';
import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import envConfig from '../../envConfig';

const ApolloAuthProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      Sentry.captureException(new Error(`GRAPHQL ERROR`));
      graphQLErrors.forEach(({ message, locations, path }) => {
        // eslint-disable-next-line
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );

        Sentry.setContext('graphql error', {
          message,
          locations,
          path,
        });
      });
    }

    if (networkError) {
      // eslint-disable-next-line
      console.log(`[Network error]: ${networkError}`);

      Sentry.captureException(new Error(`[Network error]: ${networkError}`));
    }
  });

  const baseLink = new BatchHttpLink({
    uri: envConfig.apiUrl,
    // credentials: 'same-origin',
    // fetch: !isBrowser && fetch,
  });

  const correlationId = uuid();

  Sentry.configureScope(scope => {
    scope.setTag('correlationId', correlationId);
  });

  let token;
  const authMiddlewareLink = setContext(async (_, { headers, ...rest }) => {
    try {
      token = await getAccessTokenSilently({ cacheMode: 'off' });
    } catch (error) {
      throw new Error('Token not found');
    }
    if (!token) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
        'correlation-id': correlationId,
      },
    };
  });

  const client = React.useRef();
  const link = ApolloLink.from([authMiddlewareLink, errorLink, baseLink]);

  if (!client.current) {
    client.current = new ApolloClient({
      link,
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
        },
        mutate: {
          errorPolicy: 'all',
        },
        watchQuery: {
          fetchPolicy: 'network-only',
        },
      },
    });
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};

export default ApolloAuthProvider;
