/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { paymentGateway } from '../../envConfig';

export function redirectToPaymentGateway(token) {
  const form = document.createElement('form');

  form.method = 'POST';
  form.action = paymentGateway.paymentUrl;

  const element1 = document.createElement('input');
  element1.type = 'hidden';
  element1.name = 'token';
  element1.value = `${token}`;
  form.appendChild(element1);

  document.body.appendChild(form);

  form.submit();
}

export const CREATE_PURCHASE_TOKEN = gql`
  mutation CREATE_PURCHASE_TOKEN($applicationId: String!) {
    applications {
      application(id: $applicationId) {
        createPurchaseToken
      }
    }
  }
`;
export default client => {
  const payNow = async id => {
    // return redirectToPaymentGateway(token);
    return client
      .mutate({
        mutation: CREATE_PURCHASE_TOKEN,
        variables: { applicationId: id },
      })
      .then(({ data }, error) => {
        if (error) {
          throw new Error();
        }
        const { createPurchaseToken: token } = data.applications.application;
        // console.log('token:', token);
        return redirectToPaymentGateway(token);
      });
  };

  const copyRequestPayNow = async id => {
    // return redirectToPaymentGateway(token);
    return client
      .mutate({
        mutation: CREATE_PURCHASE_TOKEN,
        variables: { applicationId: id },
      })
      .then(({ data }, error) => {
        if (error) {
          throw new Error();
        }
        const { createPurchaseToken: token } = data.applications.application;
        // console.log('token:', token);
        return redirectToPaymentGateway(token);
      });
  };

  return {
    payNow,
    copyRequestPayNow,
  };
};
