import { gql } from '@apollo/client';

export const GET_APPLICATION_AND_SERVICES = gql`
  query getApplication($appId: String!) {
    applications {
      me {
        application(id: $appId) {
          deliveryInstructions {
            id
            emailAddressToReturn
            mailOption
            mailAddress {
              id
              line1
              line2
              city
              state
              postalCode
              country
            }
          }
        }
      }
    }
    services {
      all {
        id
        name
        displayName
        description
        price
        serviceType
      }
    }
  }
`;

export default client => {
  const getServices = id => {
    return client
      .query({
        query: GET_APPLICATION_AND_SERVICES,
        variables: { appId: id },
      })
      .then((data, error) => {
        if (error) {
          // do we throw?
        }
        return {
          services: data.data.services.all,
          address:
            data.data.applications.me.application.deliveryInstructions
              .mailAddress,
        };
      });
  };

  return {
    getServices,
  };
};
