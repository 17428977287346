import React, { useState, useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import styled from 'styled-components';
import Modal from '../components/Modal/Modal';
import DashboardTemplate from './Templates/DashboardTemplate';
import ApplicationList from '../components/applicationsList/applicationsList';
import { isAdmin } from '../shared/userFunctions';

export const UPDATE_PAID_STATUS_OF_APPLICATION = gql`
  mutation updatePaidStatus($status: Boolean!, $applicationID: String!) {
    applications {
      me {
        application(id: $applicationID) {
          updatePayStatus(status: $status) {
            id
          }
        }
      }
    }
  }
`;

const COPY_REQUEST_SEND_EMAILS = gql`
  mutation copyRequestSendEmails($applicationID: String!, $id: String) {
    copyRequest {
      sendConfirmationEmail(id: $applicationID)
      updatePayStatus(id: $id)
    }
  }
`;

const UPDATE_APPLICATION_RUSH_SERVICE = gql`
  mutation updateApplicationWithNewRushService(
    $applicationID: String!
    $serviceID: String!
  ) {
    applications {
      me {
        application(id: $applicationID) {
          updateRushService(serviceID: $serviceID) {
            id
          }
        }
      }
    }
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DashboardApplicationView = () => {
  // RESETS ONBEFOREUNLOAD SO THE POPUP GOES AWAY ON REFRESH
  window.onbeforeunload = null;

  // IF THE USER IS AN ADMIN TAKE THEM TO THE ADMIN/EVALUATIONS PAGE:
  if (isAdmin()) {
    return <Navigate to="/admin/evaluations" />;
  }

  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const closeModalHandler = () => {
    navigate('/dashboard');
    setModalOpen(false);
  };
  // USED TO SEARCH URL FOR QUERY VARIABLES
  const query = useQuery();

  // RETURN FROM AUTHORIZE.NET FOR COPY REQUEST PAYMENT
  const { status } = useParams();
  const appID = query.get('appID') || {};
  const urlService = query.get('service') || {};
  if (status === 'copyRequest') {
    const [sendCopyRequestEmailConfirmation] = useMutation(
      COPY_REQUEST_SEND_EMAILS,
    );

    useEffect(() => {
      sendCopyRequestEmailConfirmation({
        variables: {
          applicationID: appID,
          id: appID,
        },
      });
    }, []);
    return (
      <DashboardTemplate>
        <p>Your copy request has been submitted. Thank you.</p>
      </DashboardTemplate>
    );
  }
  if (status === 'copyRequestCancel') {
    return (
      <DashboardTemplate>
        <p>Your order has been canceled. </p>
      </DashboardTemplate>
    );
  }

  if (status === 'upgradeRush') {
    const [updateApplicationWithNewRushService] = useMutation(
      UPDATE_APPLICATION_RUSH_SERVICE,
    );
    useEffect(() => {
      updateApplicationWithNewRushService({
        variables: {
          applicationID: appID,
          serviceID: urlService,
        },
      });
    }, []);
    return (
      <DashboardTemplate>
        <p>Your rush status has been updated. Thank you.</p>
      </DashboardTemplate>
    );
  }
  if (status === 'upgradeRushCancel') {
    return (
      <DashboardTemplate>
        <p>Your rush order has been canceled.</p>
      </DashboardTemplate>
    );
  }

  // RETURN FROM AUTHORIZE.NET FOR APPLICATION PAYMENT
  const [updatePaidStatusOfApplication] = useMutation(
    UPDATE_PAID_STATUS_OF_APPLICATION,
  );

  const successModalText = (
    <ModalMessage>
      <h2>Your application has been submitted!</h2>
      <p>
        Thank you for submitting your application! Your application will be
        processed.
      </p>
    </ModalMessage>
  );
  const cancelModalText = (
    <ModalMessage>
      <h2>Click edit application to resume payment process</h2>
      <p>
        You have canceled your payment process, please click "Edit Application"
        to continue to payment.
      </p>
    </ModalMessage>
  );

  // USEEFFECT USED TO MAKE SURE UPDATEPAIDSTATUSOFAPPLICATION IS
  // RAN ONLY ONCE SINCE AN EMAIL IS SENT DURING THAT PROCESS
  useEffect(() => {
    if (status === 'applicationPaidSuccess' && appID) {
      updatePaidStatusOfApplication({
        variables: {
          applicationID: appID,
          status: true,
        },
      });
    }
    if (
      !modalOpen &&
      (status === 'applicationPaidSuccess' ||
        status === 'applicationCancelPayment')
    ) {
      setModalOpen(true);
    }
  }, []);

  return (
    <DashboardTemplate>
      <Modal
        setModal={setModalOpen}
        open={modalOpen}
        onClose={closeModalHandler}
      >
        {status === 'applicationPaidSuccess' && successModalText}
        {status === 'applicationCancelPayment' && cancelModalText}
      </Modal>
      <ApplicationList />
    </DashboardTemplate>
  );
};

export default DashboardApplicationView;

const ModalMessage = styled.div`
  max-width: 500px;
  h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  p {
    line-height: 2;
  }
`;
