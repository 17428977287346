function createConfig(id = '') {
  return {
    initial: 'init',

    context: {
      addresses: [],
      applicationId: id,
      services: [],
    },

    on: {
      ADD_ADDRESS: { actions: ['addAddress'] },
      REMOVE_ADDRESS: { actions: ['removeAddress'] },
      SUBMIT: {
        actions: ['addEmails'],
        target: 'calculatePrice',
      },
    },

    states: {
      init: {
        invoke: {
          src: 'getServices',
          onDone: {
            actions: ['addServices'],
            target: 'waiting',
          },
          onError: {
            target: 'loadingError',
          },
        },
      },

      waiting: {},

      calculatePrice: {
        entry: ['calculatePrice', 'sendSubmit'],
        on: {
          COMPLETE: 'copyRequestOverview',
        },
      },

      copyRequestOverview: {
        invoke: {
          src: 'createCopyRequest',
          onDone: {
            actions: ['saveCopyRequestID'],
            target: 'awaitingPayment',
          },
          onError: {
            target: 'loadingError',
          },
        },
      },

      awaitingPayment: {
        on: {
          COMPLETE: 'paid',
          PREVIOUS: 'waiting',
        },
      },

      paid: {
        type: 'final',
      },

      loadingError: {},
    },
  };
}

export default createConfig;
