function createConfig(id = '') {
  return {
    initial: 'init',
    context: {
      applicationId: id,
    },

    states: {
      init: {
        invoke: {
          src: 'getAppAndServices',
          onDone: {
            actions: ['addAddressAndServices'],
            target: 'waiting',
          },
          onError: {
            target: 'loadingError',
          },
        },
      },
      waiting: {
        on: { SUBMIT: 'paying' },
      },
      paying: {
        invoke: {
          src: 'createTokenAndRedirect',
          onDone: {},
          onError: {
            target: 'loadingError',
          },
        },
      },
      loadingError: {},
    },
    on: {},
  };
}

export default createConfig;
